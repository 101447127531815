import logo from '../images/logo-text.png';
import { Link } from 'react-router-dom';

const odnosnikifooter = [
  { to: "/meble-kuchenne", title: "Meble kuchenne" },
  { to: "/szafy-i-regaly", title: "Szafy i regały" },
  { to: "/meble-biurowe", title: "Meble biurowe" },
  { to: "/meble-lazienkowe", title: "Meble łazienkowe" },
  { to: "/obszar", title: "Obszar działania" },
  { to: "/blog", title: "Blog" }
];

const Footer = () => {
  return(
    <footer>
      <div className="footer-container">
        <div className="footer-column">
          <img src={logo} className="footer-logo" alt="WilkWood" title='Logo WilkWood' loading='lazy'/>
          <p className='footer-underlogo'>Wilkwood - najlepsze <strong> meble na wymiar Tarnów</strong> i okolice. Doskonałe rozwiązania dla Twojego wnętrza. Jakość, profesjonalizm i indywidualne podejście.</p>
        </div>
        <div className="footer-column">
          <h4>Aktualności WilkWood</h4>
          <ul className='footer-ul'>
          {odnosnikifooter.map(category => (
            <li key={category.to}>
              <Link to={category.to}>{category.title}</Link>
            </li>
          ))}
          </ul>
        </div>
        <div className="footer-description">
          © 2023 WilkWood - meble na wymiar Tarnów. Wszelkie prawa zastrzeżone.
        </div>
      </div>
    </footer>
  )
}

export default Footer;