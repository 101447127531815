import { Element } from "react-scroll";
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

import tick from '../images/contact-icons/tick.png';
import cross from '../images/contact-icons/cross.png';
import phoneGif from '../images/contact-icons/phone.gif';
import emailGif from '../images/contact-icons/email.gif';
// import instaGif from '../images/contact-icons/instagram.gif';
import faceGif from '../images/contact-icons/facebook.gif';

const Contact = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [charCount, setCharCount] = useState(500);
  const [mailSent, setMailSent] = useState(false);
  const formRef = useRef();
  const messageBoxTimeoutRef = useRef(null);

  const validateForm = () => {
    const errors = {};

    if (!name.trim()) {
      errors.name = 'Pole Imię jest wymagane.';
    }

    if (surname.trim() && !/^[A-Za-z]+$/.test(surname)) {
      errors.surname = 'Proszę użyć liter.';
    }

    if (!email.trim()) {
      errors.email = 'Pole Email jest wymagane.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Podaj poprawny adres email.';
    }

    if (!phone.trim()) {
      errors.phone = 'Pole Numer telefonu jest wymagane.';
    }

    if (!message.trim()) {
      errors.message = 'Pole Wiadomość jest wymagane.';
    }

    return errors;
  };

  const handleTextareaChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 500) {
      setMessage(inputText);
      setCharCount(500 - inputText.length);
    }
  };

  const handleInputChange = (field, value) => {
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value.trim() !== '') {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });

    const fieldHandlers = {
      name: setName,
      surname: setSurname,
      email: setEmail,
      phone: setPhone,
      message: setMessage,
    };

    const handler = fieldHandlers[field];
    if (handler) {
      handler(value);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      emailjs.sendForm('service_rullhit', 'template_ok0nhfb', formRef.current, 'R0umCjN6m2YvSFcOl')
        .then((result) => {
          console.log(result.text);
          setMailSent(true);
        })
        .catch((error) => {
          console.log(error.text);
        });

      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
      setSurname('');
      setCharCount(500);
      setErrors({});

      clearTimeout(messageBoxTimeoutRef.current);
      messageBoxTimeoutRef.current = setTimeout(() => {
        closeMessageBox();
      }, 5000);
    } else {
      setErrors(formErrors);
    }
  };

  const closeMessageBox = () => {
    clearTimeout(messageBoxTimeoutRef.current);
    setMailSent(false);
  };

  return (
    <section>
      <Element id="kontakt" name="Kontakt w sprawie mebli" className="contact-section">
        <div className="section-header">
          <h2 className="section-header-about">Skontaktuj się z nami i zamów meble na wymiar</h2>
        </div>
        <div className="contact-form-container">
          <div className="contact-form">
            <h3>Napisz do nas</h3>
            <form ref={formRef} onSubmit={sendEmail}>
              <div className="contact-form-input">
                <label htmlFor="name">Imię:</label>
                <input
                  type="text"
                  name="user_name"
                  id="name"
                  value={name}
                  onChange={(e) => handleInputChange('name', e.target.value.replace(/[^A-Za-z]/g, ''))}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>
              <div className="contact-form-input">
                <label htmlFor="surname">Nazwisko:</label>
                <input
                  type="text"
                  name="user_surname"
                  id="surname"
                  value={surname}
                  onChange={(e) => handleInputChange('surname', e.target.value.replace(/[^A-Za-z]/g, ''))}
                />
                {errors.surname && <span className="error">{errors.surname}</span>}
              </div>
              <div className="contact-form-input">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  name="user_email"
                  id="email"
                  value={email}
                  onChange={(e) => handleInputChange('email', e.target.value)}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div className="contact-form-input">
                <label htmlFor="phone">Numer telefonu:</label>
                <input
                  type="tel"
                  name="user_phone"
                  id="phone"
                  value={phone}
                  onChange={(e) => handleInputChange('phone', e.target.value.replace(/\D/g, ''))}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>
              <div className="contact-form-input">
                <label htmlFor="message">Wiadomość:</label>
                <textarea
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => {
                    handleTextareaChange(e);
                    handleInputChange('message', e.target.value);
                  }}
                ></textarea>
                <span className={charCount <= 0 ? 'char-count error' : 'char-count'}>
                  {charCount === 0 ? 'Wykorzystano wszystkie znaki' : `Ilość znaków do wykorzystania ${charCount} znaków`}
                </span>
                {errors.message && <span className="error">{errors.message}</span>}
              </div>
              <button className="contact-button" type="submit">Wyślij</button>
            </form>
            {mailSent && (
            <div className="message-box">
              <img src={tick} alt="Tick" className="message-icon" />
              <span className="message-text">E-mail przesłany!</span>
              <button className="close-button" onClick={closeMessageBox}>
                <img src={cross} alt="Cross" className="close-cross" />
              </button>
            </div>
            )}
          </div>
          <div className="contact-details">
            <h3>Zapraszamy do kontaktu z nami!</h3>
            <div className="contact-info">
              <div className="contact-item">
                <img src={phoneGif} className="contact-gif" alt="Phone" loading='lazy'/>
                <a className="contact-numer" href="tel:606577091">+48 606-577-091</a>
              </div>
              <div className="contact-item">
                <img src={emailGif} className="contact-gif" alt="Email" loading='lazy'/>
                <a className="contact-numer" href="mailto:meblewilkwood@gmail.com">meblewilkwood@gmail.com</a>
              </div>
              <div className="contact-item">
                <img src={faceGif} className="contact-gif" alt="Facebook" loading='lazy'/>
                <a className="contact-numer" href="https://www.facebook.com/Dawkio/" target="_blank" rel="noreferrer">Meble WilkWood</a>
              </div>
              {/* <div className="contact-item">
                <img src={instaGif} className="contact-gif" alt="Instagram" loading='lazy'/>
                <span>Meble WilkWood</span>
              </div> */}
            </div>
            <h4 className="map-google-head">Zobacz nas na mapach google:</h4>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d82011.25101760344!2d20.9357715!3d50.0328766!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf135495fc8bbd6d%3A0xf0c80b035094f385!2sWilkWood%20-%20Meble%20na%20wymiar!5e0!3m2!1spl!2spl!4v1689760152940!5m2!1spl!2spl" title="WilkWood - Meble na wymiar Tarnów" className="google-maps" styles="border:0;" allowFullScreen="" loading="lazy" samesite="Lax" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </Element>
    </section>
  );
};

export default Contact;
